import { Position } from '@/pages/editor/component/LiveWindow/layerWorkFlow/types'
import { AdjacentClipItem } from '@/pages/editor/hook/useDragging'
import { IDraggingData } from '@/pages/editor/utils/drag'
import { ITask } from '@/pages/editor/utils/task'
import mitt, { Handler } from 'mitt'
import { BaseVariant } from 'notistack'
import {
  CaptionClip,
  CompoundCaptionClip,
  MusicLyricsClip,
  StickerClip,
  TimelineData,
  TimelineVideoFxClip,
  VideoClip,
  VideoFx,
} from './ProjectData'

export type onFinishAssetPackageInstallation =
  'onFinishAssetPackageInstallation'
type Events = {
  [key in onFinishAssetPackageInstallation]: {
    assetPackageId: string
    assetPackageFilePath: string
    assetPackageType: string
    error: number
  }
} & {
  getTaskJobList?: undefined
  pickColorInLiveWindow: NvsColor | undefined
  positionXY: { x: number; y: number }
  effectPosition: {
    isMouseUp: boolean
    width?: number
    height?: number
    x?: number
    y?: number
    resetJiuGongGe?: boolean
    regionType?: string
    regionKey?: string
  }
  monitorSelectClip: VideoClip
  mediaRotationValue: { value?: number; isMouseUp: boolean }
  mediaXYPosition: { transX?: number; transY?: number; isMouseUp?: boolean }
  editVideoRect: boolean
  saveProjectData: undefined
  monitorClearSelect: undefined
  monitorTranslateEffectVideoFxRegion: [TimelineVideoFxClip, Position?]
  InitClipEditor: undefined
  refreshCombinationMap: undefined
  stopVideoFreezeFrame: undefined
  reversePlay: undefined
  moveCanInciseRef: TimelineData
  deleteLastMusicLyricsClip: undefined
  NotifyTaskListUpdate: undefined
  isPageLoading: { status: boolean; tip?: string }
  UploadQueue: ITask[]
  MediaTranscode: string
  mouseDown: { event: MouseEvent; dragData: IDraggingData }
  mouseUp: { event: MouseEvent; dragData: IDraggingData }
  getAdjacentClipKey: AdjacentClipItem[]
  recentlyMark: boolean
  isTimelineLoading: { status: boolean }
  draggingMultiMusicLyrics: undefined
  monitorSelectTransform2DVideoFx: [
    videoFx: VideoFx,
    m3u8Path: string,
    isScaleLock?: boolean,
  ]
  isMoveSeekBar: boolean
  UpdateTask: string
  AddTask: string
  refreshTimelineUI?: { isPanelClose: boolean }
  onSeekTimelinePosition: { seekerTime: number }
  NotifyAudioRecordTranscodeTaskFinish: {
    recordInfo: { name: string }
    id: number
  }
  ERROR: { keyUUID: string; status: number; mediaUploadType: string }
  changeUndoRedoStatus: { cmdPos: number; cmdMaxPos: number }
  onWebRequestWaitStatusChange: any
  playStatus: number
  onPlaybackSubTimelinePosition: { timeline: NvsTimeline; position: number }
  onPlaybackTimelinePosition: { timeline: NvsTimeline; position: number }
  changePlay: { isPlaying: boolean; timeline: NvsTimeline }
  onPlaybackEOF: NvsTimeline
  onAudioVUMeter: {
    timeline: NvsTimeline
    leftVUValue: number
    rightVUValue: number
    timeStamp: number
  }
  grabImage: { imageData: any; time: number }
  onPlaybackSeek: number
  resizeLiveWindow?: { skipSeek: boolean }
  monitorTranslateVideoFx: [videoFx: VideoFx, m3u8Path: string]
  monitorScaleVideoFx: [
    videoFx: VideoFx,
    m3u8Path: string,
    isScaleLock: boolean,
  ]
  monitorScanVideoFx: [videoFx: VideoFx, m3u8Path: string]
  monitorRotateVideoFx: [videoFx: VideoFx, m3u8Path: string]
  monitorSelectAnimatedSticker: [panelClip: StickerClip, isScaleLock?: boolean]
  monitorRotateAnimatedSticker: [
    panelClip: StickerClip,
    direction: string,
    monitorRotateAnimatedSticker: number | undefined,
  ]
  monitorTranslateAnimatedSticker: [panelClip: StickerClip, value: Position]
  monitorOffsetTranslateAnimatedSticker: [
    panelClip: StickerClip,
    point: { x: number; y: number },
    isScaleLock: boolean,
  ]
  monitorSelectCompoundCaption: { compoundCaptionClip: CompoundCaptionClip }
  monitorTranslateCompoundCaption: {
    compoundCaptionClip: CompoundCaptionClip
    position: { x: number; y: number }
  }
  monitorPositionCompoundCaption: {
    compoundCaptionClip: CompoundCaptionClip
    position: Position
  }
  monitorSelectCaption: [CaptionClip | MusicLyricsClip, boolean?]
  monitorTranslateCaption: [CaptionClip, Position]
  monitorOffsetTranslateCaption: [CaptionClip, { x: number; y: number }]
  monitorScaleCaption: [CaptionClip, number]
  monitorTranslateSubtitleCaption: [CaptionClip, Position]
  TimelineScroll: number
  monitorSelectEffectVideoFx: [TimelineVideoFxClip]
  monitorGetDefaultEffectVideoFxRegion: Fn
  selectAspectRatio: string
  updatePanelClip: undefined
  customMessages: { message: string; type?: BaseVariant }
  previewMaterial?: IMaterialResource
  updateClipControlRectInfo: undefined
  newTrackIndex: number
  updateNearestTransition: undefined
  isProjectDuplicating: { status: boolean; tip?: string }
  subtitlesClearAll: undefined
  sliderMenuClicked: undefined
}

const emitter = mitt<Events>() // inferred as Emitter<Events>

export default {
  $on: emitter.on,
  $off: emitter.off,
  $emit: emitter.emit,
  $once: function <Key extends keyof Events>(
    type: Key,
    handler: Handler<Events[Key]>,
  ) {
    function fn(args: Events[Key]) {
      emitter.off(type, fn)
      handler(args)
    }

    return emitter.on(type, fn)
  },
}
